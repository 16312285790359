(function (app) {
    app.run(['appConfig', (appConfig) => {
        angular.extend(appConfig, {
            paymentSystems: [
                'neteller',
                'visa',
                'yandex-money',
                'mastercard',
                'skrill',
                'qiwi'
            ],
            merchants: [
                'evoplay',
                'playson',
                'playngo',
                'gameart',
                'spinomenal',
                'pragmaticplay',
                'quickspin',
                'digitain',
                'kiron',
                'amatic',
                'habanero',
                'booming-games',
                'pariplay',
                'bet-games',
                'betsoft',
                'netent',
                'microgaming',
                'evolution'
            ],
            socials: [
                'mail',
                'odnoklassniki',
                'facebook',
                'vk',
                'google-plus'
            ]
        });
    }]);
})(angular.module('app'));
