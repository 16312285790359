(function (app) {
    app.config(['appConfig', (appConfig) => {
        angular.extend(appConfig, {
            mainWPUrl: 'https://tiptopcasino.online',
            affiliateURL: 'https://affiliates.tiptopcasino.online',
            languages: [
                {code: 'en', label: 'English', iso3: 'eng'},
                {code: 'ru', label: 'Русский', iso3: 'rus'},
                //{code: 'de', label: 'German', iso3: 'deu'},
                //{code: 'ko', label: 'Korean', iso3: 'kor'},
                //{code: 'zh', label: 'Chinese', iso3: 'chn'},
            ],
            language: 'en'
        });
    }]);
})(angular.module('app'));
